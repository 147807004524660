// Components

@import 'notification';

@import 'nav';
@import 'nav-logo';
@import 'nav-tab';

@import 'content';

@import 'button';
@import 'segmented';
@import 'statement';

@import 'chapter';
@import 'row';
@import 'tile';
@import 'footer';
@import 'bot';


// 
// TODO: Dissolve into SCSS components
// 

#matrix {
    position: absolute;
    overflow: hidden;
    width: 100%;
}

@media screen and (max-width: $sizeTablet) {

    // #overlay {}

    #matrix {
        display: none;
        visibility: hidden;
    }

    nav {
        background-color: red;
    }
}

// Donate

.bavaria {
    background-color: $colorPrimary;
    // background-image: url('/assets/bavarian-pattern.svg');
}

#reset {
    color: $colorSecondary;
}

#donate-ico {
    font-size: 1rem;
    opacity: 0;
    position: absolute;
    top: 56px;
    right: 32px;


    &.animate {
        opacity: 1;
        font-size: 4rem;
    }
}