.segmented {
    line-height: 0;
    // background-color: $colorPrimary;
    // background-color: $colorGreyMedium;
    // background-color: black;
    background: linear-gradient(90deg, $colorBlue 10%, $colorGreen 50%, $colorRed 90%);
    border-radius: $spacerTiny * 10;
    padding: $spacerTiny;
    // border: $spacerTiny solid black;

    display: flex;
    flex-direction: row;

    &>.btn {
        border: none;
        flex-direction: column;
        justify-content: space-between;
        min-width: $spacerLarge * 6;
        // background-color: $colorGreyMedium;
        background-color: transparent;
        color: white;

        &>span {
            margin: $spacerLarge * .8 0 0 0;
            display: block;
            cursor: pointer;
            align-items: center;
        }

        &:hover {
            opacity: 1;
            box-shadow: none;
        }

        // &:first-child {
        // border-bottom: 1px solid red;
        // }

        &.on {
            // color: $colorPrimary;
            color: black;
            background-color: white;
            // background-color: black;
            opacity: 1;
        }
    }
}