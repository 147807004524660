// Text

.text-primary {
    color: $colorPrimary;
}

.text-red {
    color: $colorSecondary;
}

.text-bold {
    font-weight: $fontWeightBold;
}

// Margin

.m {
    &-0 {
        margin: 0;
    }

    &-1 {
        margin: $spacerTiny;
    }

    &-2 {
        margin: $spacerSmall;
    }

    &-3 {
        margin: $spacerNormal;
    }

    &-4 {
        margin: $spacerLarge;
    }

    &x-0 {
        margin-right: 0;
        margin-left: 0;
    }

    &x-1 {
        margin-right: $spacerTiny;
        margin-left: $spacerTiny;
    }

    &x-2 {
        margin-right: $spacerSmall;
        margin-left: $spacerSmall;
    }

    &x-3 {
        margin-right: $spacerNormal;
        margin-left: $spacerNormal;
    }

    &x-4 {
        margin-right: $spacerLarge;
        margin-left: $spacerLarge;
    }

    &r-0 {
        margin-right: 0;
    }

    &r-1 {
        margin-right: $spacerTiny;
    }

    &r-2 {
        margin-right: $spacerSmall;
    }

    &r-3 {
        margin-right: $spacerNormal;
    }

    &r-4 {
        margin-right: $spacerLarge;
    }
}