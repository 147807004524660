.tile {
    display: flex;
    flex-direction: column;
    background-color: white;
    min-width: $layoutTileSize;
    min-height: $layoutTileSize;
    margin: $spacerNormal $spacerNormal;
    border: $borderWidthNormal solid transparent;
    cursor: pointer;
    box-shadow: $boxShadowSmall;
    transition: $transitionNormal;
    color: $colorGreyDark;
    // border-radius: $borderRadiusSmall;

    &.active {
        display: flex;

        &.hiding {
            box-shadow: none;
            // transform: rotateY(90deg);
            min-width: 0;

            // &>*,
            // &.tile-hide {
            //     opacity: 1;
            // }
        }
    }

    // transform: scale(0.9) skew(46deg, 12deg);

    &:hover {
        // transform: scale(1.05);
        // border: $borderWidthNormal solid $colorGreyMedium;
        // box-shadow: none;
        // border: 1px solid $colorGreyMedium;
        box-shadow: $boxShadowNormal;
        transform: translateY(-2px);
        color: black;

        &>.tile-buttons {
            // opacity: 1;
            display: inherit;
        }

        &.selected {
            border: $borderWidthNormal solid $colorPrimary;
        }
    }

    &.selected {
        border: $borderWidthNormal solid black;
        color: black;
    }

    &.animate {
        // transform: rotateZ(-30deg);
        transform: scale(0.75) skew(22deg, 24deg);
        box-shadow: -8px 8px 4px rgba(0, 0, 0, .15);

        &>.tile-title {
            opacity: 0;
        }
    }

    &.hidden {
        display: none;
        // width: 0;

        &.active {
            display: flex;
        }
    }
}

.tile-buttons {
    padding: 10px 0 0 10px;
    position: absolute;
    // opacity: 0;
    display: none;
}

.tile-add,
.tile-hide {
    width: $spacerLarge * 0.75;
    height: $spacerLarge * 0.75;
    border-radius: 12px;
    opacity: .25;
    // z-index: 1;

    &:hover {
        color: $colorPrimary;
        opacity: 1 !important;
        // content: 'Hide';
    }
}

.tile-add {
    background: $colorGreen;
    border: 1px solid darken($colorGreen, .5);
}

.tile-hide {
    background: $colorRed;
    border: 1px solid darken($colorRed, .5);
}

// .tile-spacer {
// // display: flex;
// box-shadow: none !important;
// background-color: transparent;
// // position: fixed;
// display: block;
// margin-left: -130px;
// }

@import 'tile-elemets.scss'