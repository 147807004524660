* {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    outline: none;
    border: none;
}

html {
    font-size: $fontSizeNormal;
    font-weight: $fontSizeNormal;
    // line-height: 100%;
    background-color: white;
    cursor: default;

    // Avoid highlighting
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    // display: flex;

    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // display: flex;
    // flex-direction: column;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}


a {
    text-decoration: none;
    color: $colorPrimary;
    cursor: pointer;

    &:hover {
        color: black;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 900;
    letter-spacing: -.04em;
    zoom: 1.25;
}

input {
    // background-color: $colorGreyLight;
    border: 1px solid $colorGreyLight;
}

// Scrollbar transparent
::-webkit-scrollbar {
    width: 0px;
    height: 0px;
    background: transparent;
}