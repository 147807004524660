.chapter {
    background-color: $colorGreyLight;
}

.chapter-content {
    padding: 0 0 $spacerNormal 0;
}

.chapter-header {
    background-color: white;
    box-shadow: $boxShadowToBottom;
    // color: $colorPrimary;
    vertical-align: baseline;
    border-bottom: 1px solid $colorGreyLight;
    transition: $transitionNormal;

    &:hover{
        background-color: darken(white, 2);
        // box-shadow: $boxShadowNormal;
    }
}

.chapter-title {
    display: flex;
    align-items: center;
    padding: $spacerNormal $spacerLarge;
    cursor: pointer;
    // justify-content: center;

    &>* {
        cursor: pointer;
    }
}

.chapter-emoji {
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;

    // border: $check;
}

.chapter-name {
    font-weight: $fontWeightBold;
}

.chapter-icon {
    font-size: $fontSizeTiny;
    margin: -$spacerSmall $spacerNormal;
    vertical-align: middle;
}