nav {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    font-size: $fontSizeSmall;
    background-color: white; // $colorGreyDeep;
    border-bottom: 1px solid $colorGreyLight;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    z-index: 2;

    padding: 0;
    margin: 0;
    height: $layoutNavHeight;

    // box-shadow:  $boxShadowToBottom;
}

#nav-header {
    display: flex;
    padding: 0 $spacerNormal;
    height: $layoutNavHeight / 2;
}

#nav-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    height: $layoutNavHeight;
    border-radius: $spacerLarge + $spacerNormal $spacerLarge + $spacerNormal 0 0;
    padding: 0 $spacerNormal + $spacerTiny;
    height: $layoutNavHeight / 2;
    // border: $check;
}

#controls-left {
    display: flex;
    flex: 1;

    // border: $check;
}

#controls-center {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;

    // border: $check;
}

#controls-right {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: flex-end;

    // border: $check;
}

#view-controls {
    display: flex;
    flex: 1;

    // border: $check;
}

.avatar {
    width: 24px;
    height: 24px;
    border-radius: 50%;
}

// Loading

.loading-logo {
	-webkit-animation: loading-frames 1s ease both infinite;
	        animation: loading-frames 1s ease both infinite;
}

@-webkit-keyframes loading-frames {
    0% {
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
    }
    50% {
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
    }
    100% {
        -webkit-transform: rotateX(360deg);
                transform: rotateX(360deg);
        }
    }
@keyframes loading-frames {
    0% {
        -webkit-transform: rotateX(0);
                transform: rotateX(0);
    }
    50% {
        -webkit-transform: rotateX(180deg);
                transform: rotateX(180deg);
    }
    100% {
        -webkit-transform: rotateX(360deg);
                transform: rotateX(360deg);
        }
    }
    