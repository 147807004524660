#footer {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: row;
    background-color: black;
}

#footer-menu {
    display: flex;
    width: $spacerLarge * 3;
    height: $spacerLarge * 3;
    justify-content: center;
    align-items: center;
    
    cursor: pointer;

    & span {
        cursor: pointer;
    }
}

#footer-content {
    display: flex;
    background-color: white;
    font-size: $fontSizeSmall;
    // display: flex;
    // flex: 1;
    // justify-content: center;
    // align-items: center;
}