#logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 $spacerNormal 0 0;
    cursor: pointer !important;

    &>img {
        // margin: 4px 0 0 0;
        height: $spacerLarge * 1.5;
    }
}