#notification {
    z-index: 3;
    display: block;
    position: fixed;
    // width: 100%;
    padding: $spacerNormal 0;
    right: 0;
}

#notificationText {
    max-width: 200px - ($spacerNormal*2);
    text-align: left;
    background-color: transparentize(black, .44);
    box-shadow: $boxShadowNormal;
    color: white;
    padding: $spacerLarge;
    font-size: $fontSizeSmall;
    font-weight: $fontWeightBold;
    margin: 0 auto;
    width: 96px * 3;
    border-radius: $borderRadiusNormal 0 0 $borderRadiusNormal;
    backdrop-filter: blur(5px);
}