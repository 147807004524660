.btn {
    font-weight: $fontWeightBold;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: $spacerLarge * 2;
    height: 26px;
    overflow: hidden;
    // min-width: 26px;
    padding: 0 $spacerLarge;
    text-align: center;
    cursor: pointer;
    color: white;
    background-color: $colorPrimary;
    border: $spacerTiny solid $colorPrimary;
    transition: $transitionNormal;

    &-lg {
        font-size: fontSizeLarge;
        padding: $spacerLarge $spacerLarge * 2;
        border-radius: $spacerLarge * 4;
    }

    &:hover {
        background-color: lighten($colorPrimary, 5);
        box-shadow: $boxShadowNormal;
        color: white;
        transform: translateY(-$spacerTiny/2);
    }

    &-secondary {
        border: $spacerTiny solid transparent;
        // padding: $spacerTiny / 2 $spacerNormal;
        background-color: transparent;
        box-shadow: $boxShadowButton;
        color: black;

        &:hover {
            background-color: white;
            border-color: white;
            color: black;
            box-shadow: $boxShadowNormal;
        }
    }

    &-img {
        padding-left: $spacerTiny;
        justify-content: flex-start;

        &>img {
            margin-right: $spacerNormal;
            cursor: pointer;
        }
    }
}