#tabs {
    display: flex;
    align-items: flex-end;
}

.tab {
    display: flex;
    align-items: center;
    margin: $spacerNormal * 1.5 $spacerNormal;

    // Tab Look n Feel
    // 
    // display: flex;
    // align-items: center;
    // background-color: white;
    // box-shadow: $boxShadowToTop;
    // padding: $spacerNormal $spacerLarge $spacerNormal $spacerNormal;
    // margin: $spacerNormal 0 0 $spacerNormal;
    // border-radius: $borderRadiusNormal $borderRadiusNormal 0 0;
}

.tab-name {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightBold;
}