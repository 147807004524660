#bot {
    position: fixed;
    right: $spacerLarge * 3;
    bottom: $spacerLarge * 3;
}

#mo {
    cursor: pointer;
    position: absolute;
    right: - $layoutNavHeight / 1.5 / 2;
    bottom: - $layoutNavHeight / 1.5 / 2;
    font-size: $fontSizeLarge * 2;
    line-height: 1;
    // border: 2px solid $colorPrimary;

    &.hi {
        // background: #ff0000;
        // background: -moz-linear-gradient(left, #ff0000 0%, #ffffff 25%, #ffffff 75%, #ff0000 100%);
        // background: -webkit-linear-gradient(left, #ff0000 0%, #ffffff 25%, #ffffff 75%, #ff0000 100%);
        background: linear-gradient(to right, #fd8080 0%, #ffbebe 25%, #ffb6b6 55%, #fd8080 100%);
        // filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0000', endColorstr='#ff0000', GradientType=1);
        border-color: #fd8080;
        color: white;
    }

    &::before {
        // content: 'hello';
    }

    &.look-down {
        padding: 8px 0 0 8px;
    }

    &.look-up {
        padding: 4px;
    }

    &.look-straight {
        padding: 8px 4px 0;
    }
}

#moMsg {
    color: white !important;
    background-color: black;
    padding: $spacerLarge $spacerLarge*1.2 $spacerLarge;
    // box-shadow: $boxShadowNormal;
    font-size: $fontSizeNormal;
    font-weight: 500;
    font-family: monospace;
    max-width: $layoutSideSize;
    border-radius: $borderRadiusNormal * 2 $borderRadiusNormal * 2 0 $borderRadiusNormal * 2;
    margin: 0 -16px -16px 0;

    &.hi {
        background-color: lighten($color: $colorSecondary, $amount: 20);
        color: $colorSecondary;
    }

    // &.open {
    // border: $uCheck;
    // }

    &>.error {
        background-color: white;
        color: red;
    }
}