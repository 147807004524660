#statement {
    flex: 1;
    // color: $colorGreyDark;
    color: black;
    font-size: $fontSizeLarge;
    background-color: transparent;
    text-align: center;
    font-weight: $fontWeightBold;
    letter-spacing: -.05em;
    padding: $spacerLarge;
    border: none;
    cursor: text;
    transition: $transitionBasic;

    &:hover {
        background-color: $colorGreyLight;
        border: 1px solid red($color: #000000);
    } 

    &::placeholder {
        color: $colorGreyMedium;
    }

    //   border: $check;
}