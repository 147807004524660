// Variables

$check: 1px solid coral;

// $fontFamily: 'Inter',
// sans-serif;

$fontSizeNormal: 1rem; // 16px
$fontSizeTiny: $fontSizeNormal / 16 * 8; // 11px
$fontSizeSmall: $fontSizeNormal / 16 * 11; // 11px
$fontSizeLarge: $fontSizeNormal / 16 * 20; // 20px

$fontWeightNormal: 500;
$fontWeightMedium: 600;
$fontWeightBold: 700;

$colorGreyDeep: hsla(0, 0%, 22%, 1);
$colorGreyDark: hsla(0, 0%, 44%, 1);
$colorGrey: hsla(0, 0%, 66%, 1);
$colorGreyMedium: hsla(0, 0%, 88%, 1);
$colorGreyLight: hsla(0, 0%, 95%, 1);

$colorRed: hsl(0, 100%, 50%);
$colorOrange: hsl(30, 100%, 50%);
$colorYellow: hsl(50, 100%, 50%);
$colorGreen: hsl(120, 100%, 50%);
$colorBlue: hsl(210, 100%, 50%);
$colorViolet: hsl(300, 100%, 50%);

$colorPrimary: $colorBlue;
$colorSecondary: $colorRed;

$spacerNormal: 8px;
$spacerTiny: $spacerNormal / 4; // 2
$spacerSmall: $spacerNormal / 2; // 4
$spacerLarge: $spacerNormal * 2; // 16

$boxShadowToTop: 0 -1px 2px 1px $colorGreyMedium;
$boxShadowToRight: 12px 0px 12px -12px transparentize($colorGreyDark, .8);
$boxShadowToBottom: 0px 12px 12px -12px transparentize($colorGreyDark, .8);
$boxShadowNormal: 0 4px 2px transparentize(lighten($colorPrimary, 10%), .9),
0 4px 8px transparentize(darken($colorPrimary, 10%), .8);
$boxShadowSmall: 0 2px 2px transparentize(lighten($colorPrimary, 10%), .9);
$boxShadowButton: 0;

$transitionBasic: all ease 300ms;
$transitionNormal: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);

$borderWidthNormal: 1px;

$borderRadiusSmall: $spacerSmall;
$borderRadiusNormal: $spacerNormal;
$borderRadiusLarge: $spacerLarge;

$layoutNavHeight: 72px * 1.5;
$layoutTileSize: 128px;
$layoutSideSize: $layoutTileSize * 1.5;

$sizeTablet: 1023px; // 1024 - 1