content {
    margin-top: $layoutNavHeight;
    // margin-left: 16px;
    display: block;
    overflow: hidden;

    &:nth-child(1) {
        margin-top: 320px;
    }
}

.wrapper {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.wrapper-inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}