.tile-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    // border: $check;
}

.tile-image {
    width: 64px;
    height: 64px;
    margin: -$spacerLarge 0 $spacerSmall 0;
    cursor: pointer;
    // padding: 16px 0 0 0;
}

.tile-title {
    font-size: $fontSizeSmall;
    font-weight: $fontWeightBold;
    text-align: center;
    cursor: pointer;
}