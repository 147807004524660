.row {
    display: flex;
    /* overflow: scroll; */
    transition: all ease-in-out 1s;
    // padding-left: 130px;
    // border: 2px solid coral;
    flex: 1;
    /* overflow: auto; */
    justify-content: flex-start;

    &:nth-child(1)>.row-content {
        padding-top: $spacerNormal;
    }
}

.row-title {
    display: flex;
    z-index: 1;
    background-color: transparentize($colorGreyLight, .05);
    min-width: $layoutSideSize;
    max-width: $layoutSideSize;
    font-weight: $fontWeightBold;
    border-right: 1px solid $colorGreyLight;
    border-top: 1px solid $colorGreyLight;
    border-bottom: 1px solid $colorGreyLight;
    flex-direction: column;
    // justify-content: flex-end;
    justify-content: center;
    box-shadow: $boxShadowToRight;

    &-placer {
        align-items: flex-end;
        display: flex;
        padding: $spacerLarge $spacerLarge 0;
        text-transform: capitalize;
    }

    &-note {
        font-size: $fontSizeSmall;
        font-weight: $fontWeightNormal;
        padding: 0 $spacerLarge $spacerLarge;
        color: $colorGreyDark;
    }

    &.hidden {
        display: none;
    }

    // &-bg {
    // filter: blur(4px);
    // }
}

.row-content {
    display: flex;
    flex: 1;
    overflow-x: scroll;
    margin-left: - ($layoutSideSize);
    padding-left: $layoutSideSize + $spacerNormal;
    padding-right: $spacerNormal;

    // &.row-center {
    //     justify-content: center;
    // }

    &.hidden {
        justify-content: center;
    }
}

.row-spacer {
    min-width: $spacerSmall;
    display: flex;
}

.row-end {
    display: flex;
    align-items: center;
    padding: $spacerNormal $spacerLarge * 2 $spacerNormal $spacerNormal;
    color: $colorGreyDark;
    font-size: $fontSizeSmall;

    &.hidden {
        display: none;
    }
}